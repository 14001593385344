import React from "react";
import * as styles from "./languageSelect.module.css";
import { StaticImage } from "gatsby-plugin-image"

const LanguageSelect = () => {
  let newUrl = '/'
  let isEn = false
  if(typeof document !== 'undefined') {
    const { href } = document.location
    isEn = href.includes('/en/') || href.endsWith('/en') 
    const { origin, pathname } = document.location
    const newPathName = isEn ? pathname.replace('/en', '') : '/en' + pathname
    newUrl = origin + newPathName
  }
  return (
    <a href={newUrl} className={styles.link}>
      <div className={styles.root}>
        { isEn ? <StaticImage src={ `../media/flag_en.webp` } alt="Left decoration" className={styles.flag} objectFit="contain" /> : '' }
        { !isEn ? <StaticImage src={ `../media/flag_ru.webp` } alt="Left decoration" className={styles.flag} objectFit="contain" /> : '' }
        <div className={styles.dropdown}>
          <div className={styles.back}></div>
          <span>{ isEn ? 'Русский' : 'English' }</span>
          { !isEn ? <StaticImage src={ `../media/flag_en.webp` } alt="Left decoration" className={styles.flag} objectFit="contain" /> : '' }
          { isEn ? <StaticImage src={ `../media/flag_ru.webp` } alt="Left decoration" className={styles.flag} objectFit="contain" /> : '' }
        </div>
      </div>
    </a>
  )
}

export default LanguageSelect
