// extracted by mini-css-extract-plugin
export var button = "layout-module--button--Nrjvr";
export var companyAddress = "layout-module--companyAddress--2cugI";
export var contact = "layout-module--contact--MXO4-";
export var contactIcon = "layout-module--contactIcon--kiTAp";
export var contactItem = "layout-module--contactItem--GbI+n";
export var contacts = "layout-module--contacts--8uVkA";
export var contactsList = "layout-module--contactsList--Nfa9I";
export var decorLeft = "layout-module--decorLeft--D1xfm";
export var decorRight = "layout-module--decorRight--I2L3E";
export var disabled = "layout-module--disabled--SVbSr";
export var footer = "layout-module--footer--rX0Dt";
export var footerContents = "layout-module--footerContents--ucvwl";
export var footerWrapper = "layout-module--footerWrapper--xZoAz";
export var form = "layout-module--form--WSBAw";
export var formBackgroundColor = "layout-module--formBackgroundColor--6eR-m";
export var formBackgroundImage = "layout-module--formBackgroundImage--mFSVi";
export var insta = "layout-module--insta--A4VkQ";
export var links = "layout-module--links--IGJ1F";
export var siteInfo = "layout-module--siteInfo--MNT63";
export var spacer = "layout-module--spacer--Q-xAQ";
export var spacerIndex = "layout-module--spacerIndex--0wkMD";
export var telegram = "layout-module--telegram--qdLhq";
export var telegramIcon = "layout-module--telegramIcon--kMZz5";