import React, { useState } from "react";
import Header from "./header";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./layout.module.css";

const isIndex = () => {
  if(typeof document !== 'undefined') {
    const path = document.location.host === 'forgetable.ru' ? document.location.pathname.replace('/surgeon', '') : document.location.pathname
    return path.length <= 4
  }
  return false
}

const Layout = ({ children, data, isEn }) => {
  const [ status, setStatus ] = useState(0)
  const sendMail = async e => {
    e.preventDefault()
    const data = [...e.target.querySelectorAll('input, textarea')].reduce((res, next) => { 
      res[next.name] = next.value
      return res
    }, {})
    try {
      const res = await fetch('/emailSend', {
        method: 'POST',
        body: JSON.stringify(data)
      })
      await res.json()
      setStatus(1);
      [...document.querySelectorAll('input, textarea')].forEach(i => i.value = '')
    }
    catch(e) {
      setStatus(2)
    }
  }
  return <>
    <Header isEn={isEn} data={data} />
    <div>{children}</div>
    { isIndex() ? '' : <div className={styles.spacer}></div> }
    <footer className={styles.footer}>
      <div className={styles.footerContents}>
        <form className={styles.form} onSubmit={sendMail}>
          <StaticImage alt="Background" src="../media/backgroundAppointmentBlurred.png" className={styles.formBackgroundImage} />
          <div className={styles.formBackgroundColor} />
          <h2>{ isEn ? 'Create an appointment' : 'Записаться' }</h2>
          <label htmlFor="name">
            <input required type="text" placeholder={ isEn ? 'Full name' : 'ФИО' } name="name" />
          </label>
          <label htmlFor="phone">
            <input required type="phone" placeholder={ isEn ? 'Phone number' : 'Телефон' } name="phone" />
          </label>
          <label htmlFor="contactway">
            <input required type="text" placeholder={ isEn ? 'Contact via' : 'Желаемый вид связи' } name="contactway" />
          </label>
          <label htmlFor="problem">
            <textarea required name="problem" placeholder={ isEn ? 'Topic' : 'Описание проблемы' }></textarea>
          </label>
          <button className={'filled ' + styles.button}>{ isEn ? 'Send' : 'Отправить' }</button>
          { status === 1 ? <div>
            { isEn ? <p>Thank you! I will contact you as soon as possible</p> : <p>Заявка была отправлена</p>}
          </div> : ''}
          { status === 2 ? <div>
            { isEn ? <p>An error happened. Try again in a minute</p> : <p>Произошла ошибка. Попробуйте ещё раз через минуту</p>}
          </div> : ''}
        </form>
        <div className={styles.contacts}>
          <StaticImage src="../media/footerdecorleft.svg" alt="Left decoration" className={styles.decorLeft} objectFit="contain" />
          <StaticImage src="../media/footerdecorright.svg" alt="Right decoration" className={styles.decorRight} objectFit="contain" />
          <h2>{ isEn ? 'Contacts' : 'Контакты' }</h2>
          <div className={styles.contactsList}>
              <div className={styles.contact}>
                <StaticImage src="../media/map.svg" alt={ isEn ? 'Map icon' : 'Карта' } className={styles.contactIcon} objectFit="contain" objectPosition="bottom" />
                <div className={styles.contactItem}>
                  <h4>{ isEn ? 'Hartman Clinic' : 'Хартман Клиник' }</h4>
                  <p>{ isEn ? 'Moscow, Myasnitskaya st., 19' : 'Москва, Мясницкая ул., 19'}</p>
                </div>
                <div className={styles.contactItem}>
                  <h4>{ isEn ? 'Skin Clinic' : 'Клиника Кожи' }</h4>
                  <p>{ isEn ? 'Moscow, Banniy Pereulok, 2c2' : 'Москва, Банный пер., 2с2'}</p>
                </div>
              </div>
              <div className={styles.contact}>
              <StaticImage src="../media/social.svg" alt={ isEn ? 'Get in touch' : 'Связаться' } className={styles.contactIcon} objectFit="contain" objectPosition="bottom" />
                <div className={styles.contactItem}>
                  <h4>+7 (926) 395-17-59</h4>
                  <br />
                  <p>{ isEn ? `Got questions or want to book an appointment? Send me a message on WhatsApp` : 'По любым вопросам и для записи пишите в WhatsApp' }</p>
                </div>
              </div>
              <div className={styles.contact}>
                <StaticImage src="../media/email.svg" alt="E-mail" className={styles.contactIcon} objectFit="contain" objectPosition="bottom" />
                <div className={styles.contactItem}>
                  <h4><a href="mailto:Viktoriaa@yandex.ru">Viktoriaa@yandex.ru</a></h4>
                </div>
              </div>
          </div>
          {/* <div className={styles.telegram}>
            <StaticImage src="../media/telegram.svg" alt="telegram" className={styles.telegramIcon} objectFit="contain" />
            <a href="https://t.me/victoriaplastic" rel="noopener noreferrer" target="_blank">victoriaplastic</a>
          </div>
          <div className={styles.telegram}>
            <StaticImage src="../media/vk.svg" alt="vkontakte" className={styles.telegramIcon} objectFit="contain" />
            <a href="https://vk.com/victoriaandreeva_plastic" rel="noopener noreferrer" target="_blank">victoriaandreeva_plastic</a>
          </div> */}
          <div className={styles.insta}>
            <StaticImage src="../media/inst.svg" alt="vkontakte" className={styles.telegramIcon} objectFit="contain" />
            <div className={styles.links}>
              <a className="asterisk" href="https://www.instagram.com/dr.andreevavv" rel="noopener noreferrer" target="_blank">dr.andreevavv</a>
              <a className="asterisk" href="https://www.instagram.com/dr.viktoriaaplastic" rel="noopener noreferrer" target="_blank">dr.viktoriaaplastic</a>
            </div>
          </div>

            {
              isEn ? '' : <p className={styles.disabled}>
                *Является продуктом компании Meta, которая в свою очередь является экстремистской организацией, деятельность которой запрещена на территории РФ
              </p>
            }
        </div>
      </div>
    </footer>
  </>
}

export default Layout;
